export const enMessages = {
  globalTitle: 'Verify your identity',
  progressBar: {
    steps: {
      phoneVerification: 'Phone Verification',
      bankIdentification: 'Payment Verification',
      videoIdentification: 'Video Identification',
      signDocuments: 'Sign Documents',
      next: 'Next: ',
    },
  },
  selectIdentificationMethod: {
    title: 'Select identification method',
    bankIdent: {
      heading: 'Via German bank account',
      description:
        'Login to your bank to confirm a 1 EUR transfer. This will be automatically refunded.',
      hint: '(IBAN and login credentials required)',
      badge: 'Faster',
    },
    videoEid: {
      heading: 'Via video call',
      languageDescription:
        'Languages supported on video call are English, German, French, Italian, Portuguese and Spanish.',
    },
    otherMethod: 'Identify with other method',
  },
  startBankIdentification: {
    title: 'Identify via German bank account',
    titleContinue: 'Continue identification',
    descriptionContinue: 'Choose your preferred identification method',
    step1: 'Verify your phone number',
    step1Done: 'Phone number verified ({number})',
    bank: {
      step2:
        'Log into your bank to confirm a 1 EUR auto-refunded reference transfer',
      step3: 'Check and confirm your details',
    },
  },
  startVideoIdentification: {
    title: 'Identify via video call',
    description: {
      part1: 'You will need your ',
      part2: 'ID card or passport',
      part3: ' and your smartphone. ',
    },
  },
  phoneVerification: {
    title: 'Confirm phone',
    description: 'Please enter the SMS TAN sent to ',
    tanValidationError: {
      required: 'TAN is required',
      invalidLength: 'TAN requires exactly 6 digits',
      invalidFormat: 'Wrong TAN format',
    },
    success: {
      header: 'Phone verification successful!',
      descriptionBank: {
        part1: 'For the next step please have your ',
        part2: ' and ',
        part3: 'login credentials',
        part4: ' for your online banking account at hand.',
      },
      descriptionVideo: {
        part1: 'For the next step you need your ',
        part2: 'ID card or passport',
        part3: ' and your smartphone. ',
        part4:
          'If you want to use the eID function of your German ID card, please make sure to have your 6-digit PIN ready.',
      },
    },
  },
  tanRetry: {
    requestCodeIn: 'Request a new code in',
    buttonSubmit: 'Send new code',
  },
  paymentInitiation: {
    title: 'Please enter the IBAN',
    description: {
      part1: 'of ',
      part2: 'your German bank account',
      part3: '. No joint accounts supported.',
    },
    termsAndConditions: {
      label:
        'I’ve read and accept the <a>Terms and Conditions</a> of Swisscom IT Service Finance S.E.',
      hint: 'Our service provider for legal and secure electronic signatures',
      required: 'You have to agree to the Terms and Conditions',
    },
    ibanValidationError: {
      invalidIban: 'Invalid IBAN supplied',
    },
    buttonSubmit: 'Initiate payment verification',
    buttonIdnow: 'Identify via video call instead',
    error: {
      title: {
        matchForPersonDataNotFound:
          'Alternative Identification method Required',
        personAccountMatchNotFound: 'Alternative IBAN Required',
        generic: 'Identification error',
      },
      possibleNextStep: {
        generic: {
          retryable:
            '\nPlease provide a new IBAN to continue your verification process or proceed with video identification instead.',
          generic:
            '\nPlease proceed with video identification to continue your verification process.',
        },
        bankOnly: {
          retryable:
            '\nPlease provide a new IBAN to continue your verification process.',
          generic:
            '\nPlease proceed with another method to continue your verification process.',
        },
      },
      buttonRetry: 'Try another IBAN',
      buttonIdnowFallback: 'Identify via video call',
    },
  },
  paymentIntiationSuccessfull: {
    title: 'Payment verification successful',
    description: 'Finally, please check and confirm your personal data.',
  },
  videoIdentification: {
    mobile: {
      step1: 'Get your ID card or passport ready.',
      step2: 'Copy your ID reference below.',
      step3: 'Download the IDnow app and paste the code to start.',
      info: 'Depending on the workload of the video identification experts, there may be waiting times.',
      copyButton: {
        info: 'Copy your Reference:',
        label: 'Copy ID: {identificationReference}',
        success: 'Copied!',
      },
      download: {
        title: 'Download the IDnow app',
      },
    },
    successful: {
      title: 'Identification successful!',
      description: 'Click on the finish button to complete identification.',
      automaticRedirect: 'Or you will be automatically redirected in',
    },
  },
  signDocuments: {
    initial: {
      title: 'Download and check your details',
      description: 'Please review the document(s) below.',
    },
    enterTan: {
      title: 'Confirm your personal data',
      description: 'Please enter the SMS TAN sent to ',
      transactionId: 'Your transaction ID is: ',
      transactionInfo: 'Make sure it is the same in SMS just received.',
    },
    successful: {
      title: 'Identification successful!',
      description: {
        coinbase:
          'Your identity has been successfully verified. The User Agreement to which you agreed when setting up your Coinbase Account is now binding for you and for Coinbase',
        default:
          'Please make sure you have downloaded the document for future reference.',
      },
    },
    viewDocument: 'View identificaton report',
  },
  identificationLoading: {
    initializing: 'Loading …',
    connecting: 'Establishing secure connection to your bank.',
    credentials: {
      part1: 'Please have your ',
      part2: 'login credentials for your online banking',
      part3: ' account at hand.',
    },
    processing: 'Information is transmitting …',
  },
  helpPopup: {
    linkTextPart1: 'Learn more about ',
    linkTextPart2: 'how to verify your identity',
    text: 'If you have any questions, please feel free to contact our customer support team:',
    teaserText: 'Any questions? We are happy to help.',
  },
  testLabel: 'testLabel',
  testInfo: 'testInfo',
  testValidationError: 'testValidationError',
  button: {
    cancel: 'Cancel',
    back: 'Back',
    confirm: 'Confirm',
    continue: 'Continue',
    next: 'Next',
    start: 'Start',
    finish: 'Finish',
    skip: 'Skip',
  },
};
