export const deMessages = {
  globalTitle: 'Verifizieren Sie Ihre Identität',
  progressBar: {
    steps: {
      phoneVerification: 'Rufnummer Verifizieren',
      bankIdentification: 'Referenzüberweisung',
      videoIdentification: 'Video-Identifizierung',
      signDocuments: 'Digital Signieren',
      next: 'Nächster Schritt: ',
    },
  },
  selectIdentificationMethod: {
    title: 'Identifikationsmethode auswählen',
    bankIdent: {
      heading: 'Über deutsches Bankkonto',
      description:
        'Loggen Sie sich bei Ihrer Bank ein, um eine Überweisung von 1 EUR zu bestätigen. Dieser Betrag wird automatisch zurückerstattet',
      hint: '(IBAN und Zugangsdaten erforderlich)',
      badge: 'Schneller',
    },
    videoEid: {
      heading: 'Über Video-Anruf',
      languageDescription:
        'Unterstützte Sprachen für Videoanrufe: Englisch, Französisch, Italienisch, Portugiesisch, Deutsch und Spanisch.',
    },
    otherMethod: 'Mit einer anderen Methode identifizieren',
  },
  startBankIdentification: {
    title: 'Über deutsches Bankkonto identifizieren',
    titleContinue: 'Identifikation fortführen',
    descriptionContinue: 'Bitte wählen Sie eine Identifikationsmethode aus.',
    step1: 'Telefonnummer bestätigen',
    step1Done: 'Telefonnummer bestätigt ({number})',
    bank: {
      step2:
        'Loggen Sie sich bei Ihrer Bank ein und bestätigen Sie eine Überweisung von 1 EUR. Der Betrag wird automatisch zurückerstattet.',
      step3: 'Angaben prüfen und bestätigen',
    },
  },
  startVideoIdentification: {
    title: 'Über Video-Anruf',
    description: {
      part1: 'Sie benötigen Ihren ',
      part2: 'Personalausweis oder Reisepass',
      part3: ' und Ihr Smartphone. ',
    },
  },
  phoneVerification: {
    title: 'Rufnummer bestätigen',
    description:
      'Bitte geben Sie die SMS-TAN ein, die Sie soeben auf folgender Telefonnummer erhalten haben: ',
    tanValidationError: {
      required: 'Die Eingabe der TAN ist erforderlich',
      invalidLength: 'Die TAN besteht aus genau 6 Ziffern',
      invalidFormat: 'Falsches TAN-Format',
    },
    success: {
      header: 'Rufnummer erfolgreich verifiziert!',
      descriptionBank: {
        part1: 'Für den nächsten Schritt halten Sie bitte Ihre ',
        part2: ' und ',
        part3: 'Anmeldedaten',
        part4: ' zu Ihrem Online-Banking bereit.',
      },
      descriptionVideo: {
        part1: 'Für den nächsten Schritt benötigen Sie Ihren ',
        part2: 'Personalausweis oder Reisepass',
        part3: ' und Ihr Smartphone.',
        part4:
          'Wenn Sie die eID-Funktion Ihres deutschen Personalausweises nutzen möchten, halten Sie bitte Ihre 6-stellige PIN bereit.',
      },
    },
  },
  tanRetry: {
    requestCodeIn: 'Einen neuen Code anfordern in',
    buttonSubmit: 'Neuen Code senden',
  },
  paymentInitiation: {
    title: 'Bitte geben Sie die IBAN ein',
    description: {
      part1: 'IBAN eines ',
      part2: 'deutschen Bankkontos',
      part3:
        ', das unter Ihrem Namen läuft. Gemeinschaftskonten können nicht verwendet werden.',
    },
    termsAndConditions: {
      label:
        'Ich habe die <a>Allgemeinen Geschäftsbedingungen</a> der Swisscom IT Service Finance S.E. gelesen und akzeptiert.',
      hint: 'Unser Dienstleister für rechtsverbindliche und sichere elektronische Unterschriften',
      required:
        'Sie müssen den Allgemeinen Geschäftsbedingungen zustimmen um fortzufahren',
    },
    ibanValidationError: {
      invalidIban: 'Ungültige IBAN angegeben',
    },
    buttonSubmit: 'Zu meinem Bank Login',
    buttonIdnow: 'Stattdessen Video-Identifizierung starten',
    error: {
      title: {
        matchForPersonDataNotFound:
          'Alternative Identifizierungsmethode erforderlich',
        personAccountMatchNotFound: 'Alternative IBAN erforderlich',
        generic: 'Die Identifikation ist leider fehlgeschlagen',
      },
      possibleNextStep: {
        generic: {
          retryable:
            '\nBitte versuchen Sie es mit einer anderen IBAN oder fahren Sie mit Video-Identifizierung fort.',
          generic:
            '\nBitte fahren Sie mit der Video-Identifizierung fort, um den Verifizierungsprozess abzuschließen.',
        },
        bankOnly: {
          retryable:
            '\nBitte geben Sie eine neue IBAN ein, um den Verifizierungsprozess fortzusetzen.',
          generic:
            '\nBitte fahren Sie mit einer anderen Methode fort, um den Verifizierungsprozess abzuschließen.',
        },
      },
      buttonRetry: 'Andere IBAN verwenden',
      buttonIdnowFallback: 'Zur Video-Identifizierung',
    },
  },
  paymentIntiationSuccessfull: {
    title: 'Referenzüberweisung erfolgreich',
    description:
      'Abschließend überprüfen und bestätigen Sie bitte Ihre persönlichen Angaben.',
  },
  videoIdentification: {
    mobile: {
      step1: 'Halten Sie Ihren Reisepass oder Personalausweis bereit.',
      step2: 'Kopieren Sie Ihre ID-Referenz unten.',
      step3: 'IDnow-App herunterladen und Code zum Starten einfügen.',
      info: 'Je nach Auslastung der Videoidentifikations-Experten kann es zu Wartezeiten kommen.',
      copyButton: {
        info: 'Kopieren Sie Ihren Referenz:',
        label: 'Copy ID: {identificationReference}',
        success: 'Kopiert!',
      },
      download: {
        title: 'Laden Sie die IDnow-App herunter',
      },
    },
    successful: {
      title: 'Identifikation erfolgreich!',
      description:
        'Klicken Sie auf Fertig stellen, um die Identifizierung abzuschließen.',
      automaticRedirect: 'Oder Sie werden automatisch weitergeleitet in',
    },
  },
  signDocuments: {
    initial: {
      title: 'Herunterladen und überprüfen',
      description: 'Bitte überprüfen Sie die Informationen in den Dokumenten.',
    },
    enterTan: {
      title: 'Persönliche Daten bestätigen',
      description:
        'Bitte geben Sie die SMS-TAN ein, die Sie soeben auf folgender Telefonnummer erhalten haben: ',
      transactionId: 'Ihre Transaktions-ID lautet: ',
      transactionInfo:
        'Vergewissern Sie sich, dass die Transaktions-ID mit den Daten in der SMS übereinstimmt.',
    },
    successful: {
      title: 'Identifikation erfolgreich!',
      description: {
        coinbase:
          'Ihre Identität wurde erfolgreich verifiziert. Sie und Coinbase sind jetzt an die Nutzungsvereinbarung, der Sie bei der Einrichtung Ihres Coinbase-Kontos zugestimmt haben, gebunden.',
        default:
          'Bitte stellen Sie sicher, dass Sie das Dokument heruntergeladen haben.',
      },
    },
    viewDocument: 'Identifizierungsbericht anzeigen',
  },
  identificationLoading: {
    initializing: 'Lädt …',
    connecting: 'Eine sichere Verbindung zu Ihrer Bank wird hergestellt.',
    credentials: {
      part1: 'Bitte halten Sie Ihre ',
      part2: 'Online-Banking-Zugangsdaten',
      part3: ' bereit. ',
    },
    processing: 'Informationen werden übermittelt …',
  },
  helpPopup: {
    linkTextPart1: 'Erfahren Sie mehr über den ',
    linkTextPart2: 'Identifizierungsprozess',
    text: 'Bei Fragen können Sie sich gern an unser Kundensupport-Team wenden:',
    teaserText: 'Sie haben Fragen? Wir helfen gern.',
  },
  testLabel: 'testLabel',
  testInfo: 'testInfo',
  testValidationError: 'testValidationError',
  button: {
    cancel: 'Abbrechen',
    back: 'Züruck',
    confirm: 'Bestätigen',
    continue: 'Fortfahren',
    next: 'Weiter',
    start: 'Starten',
    finish: 'Fertig',
    skip: 'Überspringen',
  },
};
