import React, { Fragment, MouseEventHandler } from 'react';
import { FormattedMessage } from 'react-intl';
import FlexContainer from 'components/FlexContainer';
import Header from 'components/Header';
import Message from 'components/Message';
import { useIdentificationMethodsSelector } from 'redux/selectors';
import IdentRetryWithVideo from 'sections/IdentRetryWithVideo';

interface IBankIdentificationIbanError {
  errorMessage: string;
  onRetryClick: MouseEventHandler<HTMLButtonElement>;
  navigateToVideoIdentStart: () => void;
}

export const BankIdentificationIbanError: React.FC<
  IBankIdentificationIbanError
> = ({ errorMessage, onRetryClick, navigateToVideoIdentStart }) => {
  const errorVariant = useIdentificationMethodsSelector().video
    ? 'generic'
    : 'bankOnly';

  const errorWithCustomErrorTitle = [
    'matchForPersonDataNotFound',
    'personAccountMatchNotFound',
  ];

  const retryableErrors = [
    'personAccountMatchNotFound',
    'jointAccount',
    'accessByAuthorizedHolder',
  ];

  const errorsWithNextStepSuggestion = [
    'jointAccount',
    'identificationAttemptsExceeded',
    'matchForPersonDataNotFound',
    'previousIdentNotFound',
    'personAccountMatchNotFound',
    'accessByAuthorizedHolder',
    'ibanUsageLimitExceeded',
  ];

  return (
    <FlexContainer flexDirection="column" alignItems="flex-start">
      <Fragment>
        <Header type="error">
          <FormattedMessage
            id={`message.paymentInitiation.error.title.${
              errorWithCustomErrorTitle.includes(errorMessage)
                ? errorMessage
                : 'generic'
            }`}
          />
        </Header>

        <Message
          leadingIcon="ExclamationTriangle"
          type="error"
          marginBottom="2.5rem"
        >
          <FormattedMessage id={`error.${errorMessage}`} />
          {errorsWithNextStepSuggestion.includes(errorMessage) && (
            <FormattedMessage
              id={`message.paymentInitiation.error.possibleNextStep.${errorVariant}.${
                retryableErrors.includes(errorMessage) ? 'retryable' : 'generic'
              }`}
            />
          )}
        </Message>
      </Fragment>
      <IdentRetryWithVideo
        retryIdent={onRetryClick}
        navigateToVideoIdentStart={navigateToVideoIdentStart}
      />
    </FlexContainer>
  );
};
